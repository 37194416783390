import { ref, onMounted, onUnmounted, nextTick, Ref, watch } from "vue";
import { useDebounceFn } from "@vueuse/core";

export function useTableOfContent(
  contentContainerRef?: Ref<HTMLElement | null>,
  // Options for fine-tuning the behavior:
  // useDebounce: Whether to debounce the intersection handler
  // debounceTime: Debounce time for the intersection handler (in ms)
  // updateDelay: Delay before updating the active item (in ms)
  options = { useDebounce: false, debounceTime: 10, updateDelay: 100 },
) {
  const { width } = useDisplay();
  const activeTableOfContentsItemId = ref("");
  let sectionObserver: IntersectionObserver | null = null;
  let updateTimeout: NodeJS.Timeout | null = null;

  const visibleSections = new Map<string, number>();

  const isMinimumWidthForToc = computed(() => width.value >= 1800);

  const updateActiveTableOfContentsItem = () => {
    let highestRatio = -1;
    let mostVisibleSectionId = "";

    // Simplified iteration, now directly comparing intersection ratios
    for (const [sectionId, ratio] of visibleSections) {
      if (ratio > highestRatio) {
        highestRatio = ratio;
        mostVisibleSectionId = sectionId;
      }
    }

    if (
      mostVisibleSectionId &&
      mostVisibleSectionId !== activeTableOfContentsItemId.value
    ) {
      if (updateTimeout) {
        clearTimeout(updateTimeout);
      }
      updateTimeout = setTimeout(() => {
        activeTableOfContentsItemId.value = mostVisibleSectionId;
      }, options.updateDelay);
    }
  };

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(({ target, isIntersecting, intersectionRatio }) => {
      const element = target as HTMLElement;
      const sectionId = element.id || element.dataset.tocId || "";

      if (isIntersecting) {
        visibleSections.set(sectionId, intersectionRatio);
      } else {
        visibleSections.delete(sectionId);
      }
    });

    updateActiveTableOfContentsItem();
  };

  const createIntersectionObserver = () => {
    // Apply debounce if specified in options
    const debouncedHandleIntersection = options.useDebounce
      ? useDebounceFn(handleIntersection, options.debounceTime)
      : handleIntersection;

    return new IntersectionObserver(debouncedHandleIntersection, {
      rootMargin: "-144px 0px 0px 0px",
      threshold: [0, 0.1, 0.25, 0.5, 0.75, 1],
    });
  };

  const observeSections = (sections: NodeListOf<HTMLElement>) => {
    sections.forEach((section) => sectionObserver?.observe(section));
  };

  const setupTableOfContents = () => {
    if (contentContainerRef?.value && isMinimumWidthForToc.value) {
      const observedSections =
        contentContainerRef.value.querySelectorAll<HTMLElement>(
          "section[id], .toc-item[id]",
        );

      if (observedSections.length > 0) {
        sectionObserver = createIntersectionObserver();
        observeSections(observedSections);
      }
    }
  };

  const cleanupTableOfContents = () => {
    if (sectionObserver) {
      sectionObserver.disconnect();
      sectionObserver = null;
    }
    if (updateTimeout) {
      clearTimeout(updateTimeout);
    }
    visibleSections.clear();
  };

  onMounted(async () => {
    await nextTick();
    if (isMinimumWidthForToc.value && contentContainerRef?.value) {
      setupTableOfContents();
    }
  });

  // Watch for changes in screen size
  watch(isMinimumWidthForToc, (newValue) => {
    if (newValue && contentContainerRef?.value) {
      setupTableOfContents();
    } else {
      cleanupTableOfContents();
    }
  });

  onUnmounted(() => {
    cleanupTableOfContents();
  });

  return {
    activeTableOfContentsItemId,
    setupTableOfContents,
    isMinimumWidthForToc,
  };
}
